import { Canvas } from "@react-three/fiber";
import { OrbitControls } from "@react-three/drei";
import styles from "./styles.module.scss";
import { BackBtn } from "../../components";
import { Motorcycle } from "./Motorcycle";
const R3fPage = () => {
  return (
    <>
      <BackBtn />
      <header className={styles.header}>
        <h1>3D WORLD</h1>
      </header>
      <Canvas>
        <OrbitControls />
        <ambientLight intensity={0.5} />
        <pointLight position={[-2, 5, 2]} intensity={1} />
        <Motorcycle />
      </Canvas>
    </>
  );
};
export default R3fPage;
