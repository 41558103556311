import { Link } from "react-router-dom";
import { ReactComponent as RoundArrow } from "../../utils/icons/RoundArrow.svg";
import styles from "./styles.module.scss";
const BackBtn = ({ to }) => {
  let page = "MAIN";
  if (to === undefined) to = "/";
  if (to === "/movies") page = "MOVIES";
  return (
    <div className={styles.backBtn}>
      <Link className={styles.backLink} to={to}>
        <RoundArrow className={styles.roundArrow} />
        {`BACK TO ${page} PAGE`}
      </Link>
    </div>
  );
};
export default BackBtn;
