import React, { useEffect, useState } from "react";
import Video from "../../utils/media/blurred.mp4";
import { ReactComponent as RoundArrow } from "../../utils/icons/RoundArrow.svg";
import { getMovies } from "../../services/API";
import { MovieCard, Footer, Line, BackBtn } from "../../components";
import styles from "./styles.module.scss";
import TextAnimation from "../../animations/TextAnimation";

const MoviePage = () => {
  const [movies, setMovies] = useState();
  const [error, setError] = useState("");

  useEffect(() => {
    const fetchMovies = async () => {
      try {
        const { data } = await getMovies();
        setMovies(data.results);
      } catch (e) {
        console.log(e);
        setError(e);
      }
    };
    fetchMovies();
  }, []);

  return (
    <div className={styles.MoviePage}>
      {error && <p className={styles.errorContainer}>{error}</p>}
      <div className={styles.hero}>
        <BackBtn />
        <video autoPlay loop muted className={styles.video}>
          <source src={Video} type="video/webm" />
        </video>
        <div className={styles.textContainer}>
          <p className={styles.title}>
            BORED
            <TextAnimation />
          </p>
          <p className={styles.subtitle}>WATCH A MOVIE THEN!</p>
        </div>
        <button className={styles.button}>
          <a href="#body">
            <RoundArrow />
          </a>
        </button>
      </div>
      <Line />

      <div id="body" className={styles.bodyContainer}>
        <div className={styles.movieSection}>
          <p className={styles.subtitle}>New and popular movies:</p>
          <Line type="thin" />
          <div className={styles.cardContainer}>
            {movies &&
              movies.map((movie) => <MovieCard key={movie.id} movie={movie} />)}
          </div>
        </div>
      </div>

      <Footer />
    </div>
  );
};

export default MoviePage;
