import React from "react";
import styles from "./styles.module.scss";

const Line = ({ type }) => {
  let element = <></>;
  switch (type) {
    case "rounded":
      element = <div className={styles.rounded} />;
      break;

    case "thin":
      element = <div className={styles.thin} />;
      break;

    case "blue":
      element = <div className={styles.blue} />;
      break;

    default:
      element = <div className={styles.default} />;
      break;
  }
  return element;
};

export default Line;
