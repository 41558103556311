/*
Auto-generated by: https://github.com/pmndrs/gltfjsx
author: Alex.Ka. (https://sketchfab.com/Alex.Ka.)
license: CC-BY-NC-ND-4.0 (http://creativecommons.org/licenses/by-nc-nd/4.0/)
source: https://sketchfab.com/3d-models/honda-shadow-rs-2010-2e7cf7bc195044f4a0f60c04581e2691
title: Honda Shadow RS 2010
*/

import React from "react";
import { useGLTF } from "@react-three/drei";

export function Motorcycle(props) {
  const { nodes, materials } = useGLTF("/motorcycle.gltf");
  return (
    <group {...props} dispose={null}>
      <group rotation={[-Math.PI / 2, 0, -0.9]} scale={2.0}>
        <mesh
          geometry={nodes.Object_2.geometry}
          material={materials.Honda_Shadow_RS_2010_by_Alex_Ka}
        />
        <mesh
          geometry={nodes.Object_3.geometry}
          material={materials.aluminiumdetails}
        />
        <mesh
          geometry={nodes.Object_4.geometry}
          material={materials.black_chrome}
        />
        <mesh
          geometry={nodes.Object_5.geometry}
          material={materials.black_mate}
        />
        <mesh
          geometry={nodes.Object_6.geometry}
          material={materials.black_plastic}
        />
        <mesh
          geometry={nodes.Object_7.geometry}
          material={materials.brakelight}
        />
        <mesh geometry={nodes.Object_8.geometry} material={materials.chrome} />
        <mesh
          geometry={nodes.Object_9.geometry}
          material={materials.chromedetails}
        />
        <mesh
          geometry={nodes.Object_10.geometry}
          material={materials.headlight}
        />
        <mesh geometry={nodes.Object_11.geometry} material={materials.hondaF} />
        <mesh geometry={nodes.Object_12.geometry} material={materials.hondaR} />
        <mesh
          geometry={nodes.Object_13.geometry}
          material={materials.matedetails}
        />
        <mesh
          geometry={nodes.Object_14.geometry}
          material={materials.mirrors}
        />
        <mesh
          geometry={nodes.Object_15.geometry}
          material={materials.plasticdetails}
        />
        <mesh
          geometry={nodes.Object_16.geometry}
          material={materials.sidereflectors}
        />
        <mesh
          geometry={nodes.Object_17.geometry}
          material={materials.spdglass}
        />
        <mesh geometry={nodes.Object_18.geometry} material={materials.tire} />
      </group>
    </group>
  );
}

useGLTF.preload("/motorcycle.gltf");
