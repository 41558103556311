import React from "react";
import { Link } from "react-router-dom";
import { Line } from "../";
import styles from "./styles.module.scss";

const MovieCard = ({ movie }) => {
  const reduceText = (string, maxLength) => {
    let newString = string;
    if (string.length > maxLength) {
      newString = string.substr(0, maxLength) + "...";
    }
    return newString;
  };

  return (
    <Link
      reloadDocument
      to={`/movies/${movie.id}`}
      style={{ textDecoration: "none" }}
      className={styles.link}
    >
      <div className={styles.cardContainer}>
        <img
          className={styles.poster}
          src={"https://image.tmdb.org/t/p/w300" + movie.poster_path}
          alt={movie.title}
        />
        <Line />
        <div className={styles.text}>
          <p className={styles.text}>{reduceText(movie.title, 30)}</p>
        </div>
      </div>
    </Link>
  );
};

export default MovieCard;
