import axios from "axios";

const api = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
  responseType: "json",
});

const getRecipes = () => {
  const response = api.get(`/recipes`);
  return response;
};

const getMovies = () => {
  const response = api.get(`/movie/now_playing`);
  return response;
};

const getMovie = (movieId) => {
  const response = api.get(`/movie?movieId=${movieId}`);
  return response;
};

const getSimilarMovies = (movieId) => {
  const response = api.get(`/movie/similiar?movieId=${movieId}`);
  return response;
};

const getRandomActivity = () => {
  const response = api.get("/activity");
  return response;
};

const postRequest = (name, message) => {
  const response = api.post(`/request?name=${name}&message=${message}`, null, {
    method: "POST",
  });
  return response;
};

export {
  getRecipes,
  getMovies,
  getMovie,
  getSimilarMovies,
  getRandomActivity,
  postRequest,
};
