import React, { useEffect, useState } from "react";
import { getRecipes } from "../../services/API";
import { BackBtn } from "../../components";
import styles from "./styles.module.scss";

const FoodPage = () => {
  const [recipes, setRecipes] = useState([]);

  useEffect(() => {
    const fetchRecipes = async () => {
      try {
        const { data } = await getRecipes();
        setRecipes(data);
      } catch (error) {
        console.error(error);
      }
    };
    fetchRecipes();
  }, []);

  return (
    <div className={styles.FoodPage}>
      <BackBtn />
      <div className={styles.header}>
        <p>Oppskrifter</p>
      </div>
      <div className={styles.main}>
        {recipes &&
          recipes.map((recipe) => (
            <article className={styles.article} key={recipe.fields.name}>
              <img
                className={styles.img}
                alt="alt"
                src={recipe.fields.image.fields?.file?.url}
              />
              <div className={styles.textBox}>
                <p className={styles.subtitle}>{recipe.fields.name}</p>
                <p>{recipe.fields.description}</p>
                <br />
                <p>Ingredienser:</p>
                <ul>
                  {recipe.fields.ingredients.map((ingredient, index) => (
                    <li key={index}>{ingredient}</li>
                  ))}
                </ul>
              </div>
            </article>
          ))}
      </div>
      <div className={styles.footer}>
        <p>Martin Alfei 2022 ©</p>
      </div>
    </div>
  );
};

export default FoodPage;
