import React, { useEffect } from "react";
import { Routes, Route, useLocation } from "react-router-dom";
import {
  LandingPage,
  MoviePage,
  MovieDetailsPage,
  FoodPage,
  R3fPage,
} from "../pages";

const Router = () => {
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return (
    <Routes>
      <Route path="/" element={<LandingPage />} />
      <Route path="/movies" element={<MoviePage />} />
      <Route path="/movies">
        <Route path=":movieId" element={<MovieDetailsPage />} />
      </Route>
      <Route path="/recipes" element={<FoodPage />} />
      <Route path="/recipes">
        <Route path=":recipeId" element={<recipeDetailsPage />} />
      </Route>
      <Route path="/3d" element={<R3fPage />} />
      <Route
        path="*"
        element={
          <main style={{ padding: "1rem" }}>
            <p>There's nothing here!</p>
          </main>
        }
      />
    </Routes>
  );
};

export default Router;
