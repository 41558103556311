import React, { useState } from "react";
import { Link } from "react-router-dom";
import { ReactComponent as ProgrammingIll } from "../../utils/illustrations/undraw_programming.svg";
import { ReactComponent as LaughIcon } from "../../utils/icons/001-laugh.svg";
import { ReactComponent as MovieIcon } from "../../utils/icons/002-movie.svg";
import { ReactComponent as RecipeIcon } from "../../utils/icons/003-recipe-book.svg";
import { ReactComponent as ThreeDIcon } from "../../utils/icons/3d.svg";
import { postRequest } from "../../services/API";
import { Line } from "../../components";
import styles from "./styles.module.scss";

const LandingPage = () => {
  const [message, setMessage] = useState("");
  const [name, setName] = useState("");
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);
  const [submitted, setSubmitted] = useState(false);

  const handleSubmit = async (event) => {
    setLoading(true);
    setError(false);
    event.preventDefault();
    try {
      const data = await postRequest(name, message);
      if (data.status === 200) {
        setSubmitted(true);
      }
    } catch (error) {
      console.error(error);
      setLoading(false);
      setError(error);
    } finally {
      setLoading(false);
    }
  };
  const handleClearForm = () => {
    setName("");
    setMessage("");
    setSubmitted(false);
  };

  return (
    <div className={styles.LandingPage}>
      <div className={styles.backgroundd} />
      <div className={styles.hero}>
        <div className={styles.heroHeader}>
          <div>
            <p className={styles.title}>MARTIN</p>
            <p className={styles.subtitle}>Adabra Kadabra!</p>
          </div>
          <p>V2.5</p>
        </div>
        <div className={styles.heroContent}>
          <div className={styles.textBox}>
            <div className={styles.descriptiveText}>
              <p className={styles.text}>
                Personal website to expirement with new and exciting
                functionality.
              </p>
              <p className={styles.text}>
                This project has both a frontend and a backend. The frontend is
                built with React, JavaScript, SCSS, CSS and HTML. While the
                backend is a simple Node JS setup, and connects with 3. party
                API's.
              </p>
            </div>
          </div>
          <div className={styles.imgBox}>
            <ProgrammingIll className={styles.ill} />
          </div>
        </div>
      </div>
      <div className={styles.content}>
        <div className={styles.cardContainer}>
          <Link className={styles.cardBox} to={"/movies"}>
            <div className={styles.cardContent}>
              <p className={styles.subtitle}>Movies</p>
              <p>A page with a list of the most popular recent movies.</p>
            </div>
            <MovieIcon />
          </Link>
          <Link className={styles.cardBox} to={"/recipes"}>
            <div className={styles.cardContent}>
              <p className={styles.subtitle}>Recipes</p>
              <p>WORK IN PROGRESS</p>
              <p>A page with a list of random food recipes.</p>
            </div>
            <RecipeIcon />
          </Link>
          <div className={styles.cardBox}>
            <div className={styles.cardContent}>
              <p className={styles.subtitle}>Jokes</p>
              <p>PLACEHOLDER</p>
              <p>A page with the best jokes mankind has ever written.</p>
            </div>
            <LaughIcon />
          </div>
          <Link className={styles.cardBox} to={"/3d"}>
            <div className={styles.cardContent}>
              <p className={styles.subtitle}>3D World</p>
              <p>TESTING</p>
              <p>A page with interactive 3d models.</p>
            </div>
            <ThreeDIcon styles={{ height: "85px" }} />
          </Link>
        </div>
        <div className={styles.bio}>
          <svg className={styles.bioImg}>
            <defs>
              <clipPath id="svgPath">
                <path
                  path
                  d="M490.57872937418165,403C492.2533818685286,443.5915741234015,512.7308634894966,481.56819123232555,490.5713852448918,506.2747223702045C468.411907000287,530.9812535080835,392.5119200066962,559.0782399025995,357.62185990655286,551.2391868272736C322.7317998064095,543.4001337519476,293.72665664196313,497.6175867573912,281.23102464403183,459.24040391824855C268.73539264610054,420.86322107910587,274.2495953752115,355.7226882500046,282.648067918965,320.97608979241755C291.0465404627185,286.2294913348305,298.642626179912,260.4692818664968,331.6218599065528,250.76081317272644C364.6010936331936,241.05234447895612,454.03065870087187,237.35207982524994,480.52347027881,262.7252776297955C507.01628185674815,288.09847543434114,488.9040768798347,362.4084258765985,490.57872937418165,403C492.2533818685286,443.5915741234015,512.7308634894966,481.56819123232555,490.5713852448918,506.2747223702045"
                  fill="#0066ff"
                  transform="translate(-235 -235)"
                />
              </clipPath>
            </defs>
          </svg>

          <div className={styles.horizontalLine} />
          <div className={styles.textContainer}>
            <div className={styles.verticalLine} />
            <div className={styles.textBox}>
              <div>
                <h1 className={styles.subtitle}>Me, myself and I</h1>
                <p>Proffecional - Humorus - Down to earth</p>
              </div>
              <p>
                Martin is a part time junior developer at Arribatec, and full
                time student at University of Agder, Norway. Currently studying
                for a bachelor in Computer Engineering with a software focus.
              </p>
              <p>
                As a frontend developer, Martin has come to love the framework
                React and is profficient in both JavaScript and TypeScript,
                aswell as CSS, SCSS and HTML. Furthermore he has started with
                Node.JS API and testing the backend waters.
              </p>
              <p>
                Martin thrives when he can contribute with all types of jokes,
                escpesially of the dry kind! You can often find him in the wild,
                happily on hikes and excursions, or on a roadtrip to Mars. Sigh,
                someday...
              </p>
            </div>
          </div>
        </div>
        <form name="contact" onSubmit={(event) => handleSubmit(event)}>
          <h1>Reach out!</h1>
          <Line type="blue" />

          <label>
            <p>Name *</p>
            <input
              type="text"
              required
              name="name"
              placeholder="Your name here..."
              maxLength={50}
              value={name}
              onChange={(event) => setName(event.target.value)}
            />

            {name.length < 50 ? (
              <p className={styles.length}>{`${name.length}/50`}</p>
            ) : (
              <p
                className={styles.lengthError}
              >{`Maximum amount of characters reached! - ${name.length}/50`}</p>
            )}
          </label>
          <label>
            <p>Message *</p>
            <textarea
              name="message"
              required
              placeholder="Your message here..."
              maxLength={1024}
              value={message}
              onChange={(event) => setMessage(event.target.value)}
            ></textarea>
            {message.length < 1024 ? (
              <p className={styles.length}>{`${message.length}/1024`}</p>
            ) : (
              <p
                className={styles.lengthError}
              >{`Maximum amount of characters reached! - ${message.length}/1024`}</p>
            )}
          </label>

          {submitted ? (
            <>
              <h1 className={styles.feedback}>Thank you!</h1>
              <button onClick={handleClearForm} className={styles.feedback}>
                Send another
              </button>
            </>
          ) : loading ? (
            <button disabled className={styles.disabled} type="submit">
              <p>Loading...</p>
            </button>
          ) : (
            <>
              <button type="submit">
                <p>Send</p>
              </button>
              {error ? (
                <p className={styles.errorText}>
                  An error occured! Please try again later...
                </p>
              ) : null}
            </>
          )}
        </form>
      </div>
      <div className={styles.footer}>
        <p>Martin Alfei 2022 ©</p>
      </div>
    </div>
  );
};

export default LandingPage;
