import Router from "./router/Router";
import styles from "./App.module.scss";

function App() {
  return (
    <div className={styles.App}>
      <Router />
    </div>
  );
}

export default App;
