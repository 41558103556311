import React, { useState } from "react";
import { useInterval } from "react-use";
import styled, { keyframes } from "styled-components";

export default function TextAnimation() {
  const questionmarksArray = "??".split("");
  const utropstegnArray = "!!!!".split("");
  const [items, setItems] = useState(questionmarksArray);
  const [count, setCount] = useState(0);

  useInterval(() => {
    setItems(questionmarksArray);
    setCount(count + 1);

    if (count === 1) {
      setCount(0);
      setItems(utropstegnArray);
    }
  }, 12000);

  return (
    <Wrapper>
      {items.map((item, index) => (
        <span key={index}>{item}</span>
      ))}
    </Wrapper>
  );
}

const animation = keyframes`
    0% {opacity: 0; transform: translateY(-100px) skewY(10deg) skewX(10deg) rotateZ(30deg); filter: blur(10px);};
    10% {opacity: 1; transform: translateY(0) skewY(0) skewX(0) rotateZ(0); filter: blur(0); };
    90% {opacity: 1; transform: translateY(0) skewY(0) skewX(0) rotateZ(0); filter: blur(0); };
    100% {opacity: 0; transform: translateY(-100px) skewY(10deg) skewX(10deg) rotateZ(30deg); filter: blur(10px); };
`;

const Wrapper = styled.span`
  display: inline-block;

  span {
    display: inline-block;
    opacity: 0;
    animation: ${animation} 12s linear infinite;
    animation-timing-function: cubic-bezier(0.075, 0.82, 0.165, 1);
  }
  span:nth-child(1) {
    animation-delay: 0.1s;
  }
  span:nth-child(2) {
    animation-delay: 0.2s;
  }
  span:nth-child(3) {
    animation-delay: 0.3s;
  }
  span:nth-child(4) {
    animation-delay: 0.4s;
  }
`;
