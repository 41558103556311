import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { getMovie, getSimilarMovies } from "../../services/API";
import { Footer, MovieCard, BackBtn } from "../../components";
import styles from "./styles.module.scss";

const MovieDetailsPage = () => {
  const params = useParams();

  const [movie, setMovie] = useState();
  const [similarMovies, setSimilarMovies] = useState();

  useEffect(() => {
    const fetchMovie = async () => {
      try {
        const { data } = await getMovie(params.movieId);
        setMovie(data);
      } catch (error) {
        console.log(error);
      }
    };
    fetchMovie();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    const fetchSimilarMovies = async () => {
      try {
        const { data } = await getSimilarMovies(params.movieId);
        setSimilarMovies(data.results);
      } catch (error) {
        console.log(error);
      }
    };
    fetchSimilarMovies();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <div className={styles.MovieDetailsPage}>
      <BackBtn to={"/movies"} />
      <div className={styles.content}>
        <div className={styles.container}>
          {movie ? (
            <div className={styles.hero}>
              <img
                src={"https://image.tmdb.org/t/p/w300" + movie.poster_path}
                alt={movie.title}
              />
              <div className={styles.details}>
                <div className={styles.line} />
                <p className={styles.title}>{movie.title}</p>
                <p className={styles.tagline}>{movie.tagline}</p>
                <p>{movie.overview}</p>
                <p>
                  <b>Release date: </b>
                  {movie.release_date}
                </p>
                <p>
                  <b>Rating: </b>
                  {movie.vote_average}
                </p>
                <p>
                  <b>Votes: </b>
                  {movie.vote_count}
                </p>
              </div>
            </div>
          ) : (
            <div>
              <p>No movie registered!</p>
            </div>
          )}
          <div className={styles.line} />
          <p className={styles.title}>More like this:</p>
          <div className={styles.more}>
            {similarMovies &&
              similarMovies.map((movie) => <MovieCard movie={movie} />)}
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default MovieDetailsPage;
