import styles from "./styles.module.scss";
const Footer = () => {
  return (
    <div className={styles.footer}>
      <p>Images and data originates from:</p>
      <a href="https://www.themoviedb.org/">The Movie DB</a>
      <p className={styles.copyRight}>Martin Alfei 2022 ©</p>
    </div>
  );
};
export default Footer;
